<template>
    <intro-slot>
        <template v-slot:main></template>
    </intro-slot>
</template>
<script>
import IntroSlot from '@/slot/introSlot.vue';
export default {
  name: 'IntroView',
  components: {
    'intro-slot': IntroSlot
  },
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  }
};
</script>
<style scoped></style>
